import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class LC1ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="LC1"
          description="View route information and buy your ticket for the LC1 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">LC1 service</h1>
              <p>Harehills Lane/York Road - Leeds City Academy</p>
              <p>Leeds City Academy - Harehills Lane/York Road</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="LC1 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1s9EC2ORt9IjEfFOKhMpyqkuSsVQwiEUl"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Leeds City Academy
                </p>
                <p>
                  From Harehills Lane at York Road then all stops via, Harehills Lane, Roundhay Road, Spencer Place, Cowper Street, Chapeltown Road, Sheepscar Interchange, Meanwood Road, Oatland Road, Servia Hill, Servia Road, Woodhouse Street, Rampart Road. Drop off at Cinder Moor Car Park.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="LC1 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1xuPc7hqb_9MQASJz-xgIwwNmMJF60U_b"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Leeds City Academy
                </p>
                <p>
                  Pick up at Cinder Moor Car Park, Rampart Road, Servia Road, Servia Hill, Oatland Road, Meanwood Road, Sheepscar Interchange, Chapeltown Road, Cowper Street, Spencer Place, Roundhay Road, Harehills Lane to Harehills Lane at York Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Harehills lane/York Road</td>
                      <td>DEP</td>
                      <td>0733</td>
                    </tr>
                    <tr>
                      <td>Harehills</td>
                      <td>DEP</td>
                      <td>0738</td>
                    </tr>
                    <tr>
                      <td>Spencer Place</td>
                      <td>DEP</td>
                      <td>0743</td>
                    </tr>
                    <tr>
                      <td>Oatland Road</td>
                      <td>DEP</td>
                      <td>0753</td>
                    </tr>
                    <tr>
                      <td>Leeds City Academy</td>
                      <td>ARR</td>
                      <td>0810</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Leeds City Academy</td>
                      <td>DEP</td>
                      <td>1505</td>
                    </tr>
                    <tr>
                      <td>Oatland Road</td>
                      <td>DEP</td>
                      <td>1517</td>
                    </tr>
                    <tr>
                      <td>Spencer Place</td>
                      <td>DEP</td>
                      <td>1527</td>
                    </tr>
                    <tr>
                      <td>Harehills Place</td>
                      <td>DEP</td>
                      <td>1537</td>
                    </tr>
                    <tr>
                      <td>Harehills Ln/York Rd</td>
                      <td>ARR</td>
                      <td>1542</td>
                    </tr>
                  </tbody>
                </table>

                <h2 style={{ margin: '0 0 20px 0'}}>22/23 fares</h2>
                <p>There is only one payment option for this service:</p>
                <ul>
                  <li><strong>Single one way fare -  £1.</strong></li>
                  <li>Drivers accept cash and contactless payments.</li>
                  <li><strong>PLEASE NOTE:</strong> Metro pre paid passes will not be accepted on this service from September 2022.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default LC1ServicePage
